import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"
import Button from "../components/button"

const StyledTakkPage = styled.div`
  margin-top: 3rem;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  button {
    margin-top: 3rem;
  }
`

const TakkPage = () => {
  const query = useStaticQuery(graphql`
    query {
      contentfulGeneral {
        submissionThankYouText {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>Success | Agnes Suto</title>
      </Helmet>
      <Layout>
        <StyledTakkPage>
          <div
            dangerouslySetInnerHTML={{
              __html:
                query.contentfulGeneral.submissionThankYouText
                  .childMarkdownRemark.html,
            }}
          ></div>
          <Link to="/">
            <Button>Tilbaka</Button>
          </Link>
        </StyledTakkPage>
      </Layout>
    </>
  )
}

export default TakkPage
